<template>
  <v-menu
    ref="menu"
    v-model="showMenu"
    :close-on-content-click="false"
    :return-value.sync="dateRanges"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateRangeString"
        :label="$t('Time.TimeRange')"
        append-icon="mdi-calendar"
        readonly
        outlined
        dense
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateRanges" no-title scrollable range>
      <v-btn text @click="onTodaySelection"> Today </v-btn>
      <v-spacer></v-spacer>
      <!-- <v-btn text color="primary" @click="menu = false"> Cancel </v-btn> -->
      <v-btn text color="primary" @click="saveSelections"> Apply </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Array,
      default: function () {
        return [
          moment().startOf("day").format(),
          moment().endOf("day").format(),
        ];
      },
    },
  },
  data() {
    return {
      showMenu: false,
      dateRangeString: "",
      dateRanges: JSON.parse(JSON.stringify(this.value)),
    };
  },
  created() {
    this.renderDateRangeString();
  },
  methods: {
    saveSelections() {
      if (!this.dateRanges[0] && !!this.dateRanges[1]) {
        this.dateRanges[0] = moment().startOf("day").format();
        this.dateRanges[1] = moment().endOf("day").format();
      } else if (!this.dateRanges[1]) {
        this.dateRanges[1] = moment(this.dateRanges[0]).endOf("day").format();
      }
      this.dateRanges[0] = moment(this.dateRanges[0]).startOf("day").format();
      this.dateRanges[1] = moment(this.dateRanges[1]).endOf("day").format();
      if (moment(this.dateRanges[1]).isBefore(this.dateRanges[0])) {
        var newStart = this.dateRanges[1];
        this.dateRanges[1] = moment(this.dateRanges[0]).endOf("day").format();
        this.dateRanges[0] = moment(newStart).startOf("day").format();
      }
      this.renderDateRangeString();
      this.$refs.menu.save(this.dateRanges);
      this.$emit("input", this.dateRanges);
    },
    onTodaySelection() {
      this.dateRanges[0] = moment().startOf("day").format();
      this.dateRanges[1] = moment().endOf("day").format();
      this.renderDateRangeString();
      this.$refs.menu.save(this.dateRanges);
      this.$emit("input", this.dateRanges);
    },
    renderDateRangeString() {
      var start = moment(this.dateRanges[0]).format("DD/MM/YYYY");
      var end = moment(this.dateRanges[1]).format("DD/MM/YYYY");
      if (start == end) {
        if (start == moment().format("DD/MM/YYYY")) {
          start = `Today, ${start}`;
        }
        this.dateRangeString = start;
        return;
      }
      this.dateRangeString = `${start} - ${end}`;
    },
  },
};
</script>
<template>
  <td v-if="renderCell" :class="className">
    <template v-if="field == 'dateConfirmed'">
      <div class="d-flex flex-column">
        <b>{{ moment(getNestedValue(field, dataItem)).format('YYYY-MM-DD HH:mm:ss') }}</b>
      </div>
    </template>
    <template v-else-if="field == 'patientID'">
      <span class="primary--text">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else-if="field == 'patientName'">
      <span class="primary--text">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else-if="field == 'staffConfirmName'">
      <span class="font-weight-bold">{{ getNestedValue(field, dataItem) }}</span>
    </template>
    <template v-else>
      {{ getNestedValue(field, dataItem) }}
    </template>
  </td>
  <td v-else-if="renderArrow" key="'g' + columnIndex" :class="'k-group-cell'" ></td>
  <td v-else-if="columnIndex <= level" key='g-colspan'
      :colSpan="columnsCount - columnIndex">
      <p class="k-reset" :class="{'red--text': level == 1}">
          <a
            @click="onClick"
            tabIndex="-1"
            href="#"
            :class="expanded ? 'k-i-collapse k-icon' : 'k-i-expand k-icon'"
          />
          {{dataItem[field]}}
      </p>
  </td>
</template>
<script>
function nestedValue(fieldName, dataItem) {
    const path = fieldName.split('.');
    let data = dataItem;
    path.forEach((p) => {
        data = data ? data[p] : undefined;
    });

    return data;
}
import moment from "moment";
export default {
  name: 'DetailCommonKendoDataComponent',
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  computed: {
    renderArrow(){
      var returnValue = this.columnIndex === undefined ||this.level === undefined ||
        this.columnIndex < this.level ||this.columnsCount === undefined ||
        this.rowType !== 'groupHeader' || this.dataItem[this.field] === undefined;
      return returnValue && this.dataItem[this.field];
    },
    renderCell(){
      return this.field !== undefined && this.rowType !== 'groupHeader';
    }
  },
  methods: {
    moment,
    getNestedValue: nestedValue,
    onClick() {
      this.$emit('click');
    },
  }
}
</script>

<style>

</style>
<template>
  <v-autocomplete
    v-model="clinicID"
    :items="clinics"
    @change="onClinicSection"
    dense
    outlined
    label="Select clinic"
    append-icon="mdi-account-box-outline"
  >
  </v-autocomplete>
</template>

<script>
import BrandService from "@/services/brand";

export default {
  props: {
    value: {
      type: Number,
      default: function () {
        return null;
      },
    },
  },
  created() {
    this.renderListClinics();
    if (this.value) {
      this.clinicID = JSON.parse(JSON.stringify(this.value));
    }
  },
  data() {
    return {
      clinicID: this.$store.getters.userData.clinicID,
      clinics: [],
    };
  },
  methods: {
    async renderListClinics() {
      const result = await BrandService.searchBrandClinics(1, 100);
      if (!result || result.error) {
        this.$toast.error("Can not get list clinics. Please try again later.");
        return;
      }
      result.items = result.items.reverse();
      this.clinics = result.items.map((i) => ({
        value: i.clinicID,
        text: i.clinicName,
      }));
    },
    onClinicSection() {
      this.$emit("input", this.clinicID);
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="d-block">
    <pager-container 
      :total="list.total"
      :config="configPager"
      v-model="configPager"
      >
      <template>
        <v-sheet class="mt-1 mb-1">
          <span class="font-weight-bold text-body-2">Export</span>
          <div class="d-flex flex-row">
            <download-excel
              :data="list.items"
              :fields="excelConfig.fields"
              type="xls"
              :name="excelConfig.name"
              :header="excelConfig.header"
              :worksheet="excelConfig.worksheet"
              :footer="excelConfig.footer"
              >
              <v-icon size="25" color="#007c00" style="cursor: pointer">mdi-microsoft-excel</v-icon>
            </download-excel>
          </div>
        </v-sheet>

        <div class="d-block mt-2">
          <grid-custom
            :columns="columns"
            :config="configGrid"
            :dataItems="list.items"
          />
        </div>
      </template>
    </pager-container>

    <v-sheet class="mt-2">
      <v-row>
        <v-col xl="4" md="5" sx="12">
         <v-card outlined elevation="0">
            <v-card-title>
              <span clas="text-subtitle-1">Summary</span> 
            </v-card-title>
            <v-card-text>
              <v-sheet>
                <div class="d-flex mb-1 mt-1 justify-space-between">
                  <div class="text-uppercase font-weight-bold mr-5 d-flex align-center">
                    <v-icon size="25">mdi-cash</v-icon>
                    <span class="ml-2">{{ $t("Print.TotalFinalAdjustmentPrice") }}: </span>
                  </div>
                  <label class="font-weight-bold red--text ml-5">{{ convertPriceString(totalFinalPrice) }}</label>
                </div>
                <div class="d-block mt-5 w-100">
                  <div class="mt-1 mb-1 font-weight-bold d-flex align-center">
                    <v-icon size="25">mdi-format-list-bulleted-square</v-icon>
                    <span class="ml-2">List Cash & Receptionist</span>
                  </div>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="10" class="ma-0 pa-0">
                      <v-simple-table>  
                        <template #default>
                          <thead>
                            <tr>
                              <th>STT</th>
                              <th>Receptionist</th>
                              <th>Cash</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="priceByReceptionists.length">
                              <tr v-for="(itemPreCast, index) in priceByReceptionists" :key="index"  class="font-weight-bold">
                                <td>{{ index + 1 }}</td>
                                <td>{{ itemPreCast.name }}</td>
                                <td class="red--text">{{ currencyFormat(itemPreCast.price, 'end', ' VND') }}</td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td colspan="3" class="align-center">
                                  <span class="red--text font-weight-bold">No records</span>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>

                </div>
              </v-sheet>
            </v-card-text>
         </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import PagerKendoContainer from '@/components/kendo/PagerContainerComponent'
import GridDataCustom from '@/components/kendo/GridDataComponent'
import ReportService from "@/services/report";
import { convertPriceString, currencyFormat } from "@/plugins/helper";
import KendoDetailCell from "./kendoComponents/KendoDetailCell"
import moment from 'moment'
import _ from 'lodash'
import JsonExcel from "vue-json-excel";

export default {
  name: "CommonKendoPaymentComponent",
  components: {
    "pager-container": PagerKendoContainer,
    "grid-custom": GridDataCustom,
    "download-excel": JsonExcel
  },
  watch: {
    configPager() {
      this.configGrid = {
        ...this.configGrid,
        take: this.configPager.take,
        skip: this.configPager.skip
      }
    }
  },
  data() {
    return {
      dataResult: [],
      configPager: {
        skip: 0,
        take: 10
      },
      list: {
        loading: false,
        items: [],
        total: 0
      },
      columns: [
        { field: 'order', title: 'STT', width: '80px', columnMenu: 'headColumnMenu'},
        { field: 'dateConfirmed', title: 'Date', filter:'date', width:'200px'},
        { field: 'patientID', title: 'PatientID', width:'180px', columnMenu: 'headColumnMenu' },
        { field: 'patientName', title: 'PatientName', width: '200px', columnMenu: 'headColumnMenu' },
        { field: 'orderItemString', title: 'Content', width: '300px', columnMenu: 'headColumnMenu'},
        { field: 'promotionName', title: 'Promotion', width: '150px', columnMenu: 'headColumnMenu'},
        { field: 'insurrance', title: 'Insurrance', width: '150px', columnMenu: 'headColumnMenu' },
        { field: 'originalPriceStr', title: 'OriginalPrice', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalOriginalPriceCell},
        { field: 'promotionPriceStr', title: 'Discount', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalDiscountPriceCell },
        { field: 'finalAdjustmentPriceStr', title: 'Adjust', width: '200px',columnMenu: 'headColumnMenu', footerCell: this.totalAdjustPriceCell },
        { field: 'finalPriceStr', title: 'RealPayment', width: '200px', columnMenu: 'headColumnMenu', footerCell: this.totalFinalPriceCell },
        { field: 'staffConfirmName', title: 'Receptionist', width: '150px', columnMenu: 'headColumnMenu' },
        { field: 'note', title: 'Note', width: '100px' }
      ],
      sourceColumn: [],

      configGrid: {
        sortable: true,
        filterable: false,
        groupable: true,
        reorderable: true,
        resizable: true,
        columnMenu: true,

        skip: 0,
        take: 20,
        sort: [],
        group: [],
        filter: null,
        custom: true,
        cellComponent: KendoDetailCell, // use if the grid data turn on groupable TRUE
      },

      totalFinalPrice: 0,
      priceByReceptionists: [],

      // using for export
    
      excelConfig: { 
        fields: {
          'STT' : 'order',
          'Ngày tạo': 'dateCreated',
          'Ngày thanh toán': {
            field: 'dateConfirmed', 
            callback: (value) => {
              return moment(value).format("DD/MM/YYYY - HH:mm:ss")
            }
          },
          'Mã BN': 'patientID',
          'Tên BN': 'patientName',
          'Nội dung': {
            field: 'orderItemString',
            callback: (value) => {
              return value.replace(',', '\n')
            }
          },
          'CT Khuyến mãi': 'promotionName',
          'BHYT': 'insurrance',
          'Giá gốc': 'originalPrice',
          'Giá KM': {
            field: 'promotionPrice',
            callback: (value) => {
              return value == "N/A" || !_.isNumber(value) ? 0 : value  
            }
          },
          'Giá điều chỉnh': {
            field: 'finalAdjustmentPrice',
            callback: (value) => {
              return value == "N/A" || !_.isNumber(value) ? 0 : value  
            }
          },
          'Thực nhận': 'finalPrice',
          'Thu ngân': 'staffConfirmName',
          'Ghi chú': 'note'
        
        },
        worksheet: 'Sheet 1',
        name: 'PaymentReport.xls',
        header: 'Daily Payment Report',
        footer: []
      }
      
    }
  },
  
  methods: {
    convertPriceString,
    currencyFormat,
    async renderPaymentList(dateRanges, clinicID) {
    
      if (!dateRanges || !clinicID) return;
      this.list.loading = true;
      var [timeStart, timeEnd] = dateRanges;
      timeStart = moment(timeStart).format("YYYY-MM-DD");
      timeEnd = moment(timeEnd).format("YYYY-MM-DD");

      // update header name for excel 
      this.excelConfig.header = `Daily payment report (${timeStart})` 

      var result = await ReportService.payment(clinicID, timeStart, timeEnd);
      this.list.loading = false;
      if (!result || result.error) {
        return;
      }
      result.forEach((item, index) => {
        item.order = index + 1;
        item.dateCreated = `${moment(item.dateCreated).format("DD/MM/YYYY")} - ${moment(item.dateCreated).format("HH:mm:ss")}`
        item.orderItemString = item.orderItems
          .map((o) => o.itemName)
          .join(", ");

        item.finalAdjustmentPrice = item.adjustmentPrice + item.additionalPrice;

        item.originalPriceStr = this.convertPriceString(item.originalPrice);
        item.promotionPriceStr = `(${this.convertPriceString(
          item.promotionPrice
        )})`;
        item.finalAdjustmentPriceStr = this.convertPriceString(
          item.finalAdjustmentPrice
        );
        if (item.finalAdjustmentPriceStr.includes("- ")) {
          item.finalAdjustmentPriceStr = `(${item.finalAdjustmentPriceStr.replace(
            "- ",
            ""
          )})`;
        }
        item.finalPriceStr = this.convertPriceString(item.finalPrice);
        item.insurrance = "N/A";
        for (var key in item) {
          if (!item[key]) {
            item[key] = "N/A";
          }
        }
      });
      this.list.items = result;
      this.list.total = result.length;
      this.calculateTotals();

      // add footer for the excel config
      this.excelConfig.footer = [
        `Tổng tiền gốc:  <b style="color:red">${this.getCurrency('originalPrice')}</b>`,
        `Tổng tiền KM:  <b style="color:red">${this.getCurrency('promotionPrice')}</b>`,
        `Tổng tiền điều chỉnh:  <b style="color:red">${this.getCurrency('finalAdjustmentPrice')}</b>`,
        `Tổng thành tiền:  <b style="color:red">${this.getCurrency('finalPrice')}</b>`
      ];
    },
    calculateTotals() {
      this.totalFinalPrice = this.list.items
        .map((i) => i.finalPrice || 0)
        .reduce((a, b) => a + b, 0);
      this.priceByReceptionists = [
        ...new Set(
          this.list.items.map((i) => i.staffConfirmID).filter((i) => i != null)
        ),
      ].map((id) => {
        return {
          id,
          name: this.list.items.find((i) => i.staffConfirmID == id)
            .staffConfirmName,
          price: this.list.items
            .filter((i) => i.staffConfirmID == id)
            .map((i) => i.finalPrice || 0)
            .reduce((a, b) => a + b, 0),
        };
      });
    },

    getCurrency(nameProps) {
      if (this.list.items.length)
      {
        let total = this.list.items.reduce((previous, current) => {
          if (_.isNumber(current[nameProps])) {
            return previous + current[nameProps]
          } else {
            return previous;
          }
        }, 0)
        return this.currencyFormat(total,'end',' VND')
      } else {
        return 0;
      }
    },

    formatReturnTotalCurrency(h, value) {
      return h('div', {
          'class': ['d-flex w-100 justify-space-between']
        }, [
        h('span', {}, ['Total: ']),
        h('b', {
          'style': {'color': 'red', 'textAlign': 'right'}
        }, [value])
      ]);
    },

    totalOriginalPriceCell(h) {
      let totalOriginal = this.list.items.length > 0 ? this.getCurrency('originalPrice'): 0
      return this.formatReturnTotalCurrency(h, totalOriginal);
    },
    totalDiscountPriceCell(h) {
      let totalDiscount = this.list.items.length > 0 ? this.getCurrency('promotionPrice'): 0
      return this.formatReturnTotalCurrency(h, totalDiscount);
    },
    totalAdjustPriceCell(h) {
      let totalAdjust = this.list.items.length > 0 ? this.getCurrency('finalAdjustmentPrice'): 0
      return this.formatReturnTotalCurrency(h, totalAdjust);
    },
    totalFinalPriceCell(h) {
      let totalFinal = this.list.items.length > 0 ? this.getCurrency('finalPrice'): 0
      return this.formatReturnTotalCurrency(h, totalFinal);
    },

  }
}
</script>

<style>

</style>
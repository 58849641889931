<template>
  <v-data-table
    :headers="list.headers"
    :items="list.items"
    :loading="list.loading"
    :items-per-page="list.items.length"
    hide-default-footer
   
    disable-sort
    class="app-custom-table"
    mobile-breakpoint="0"
    dense
  > 
    
    <template v-slot:[`item.dateConfirmed`]="{ item }">
      <span style="font-size: 11px">{{
        moment(item.dateConfirmed).utcOffset("+1400").format("DD/MM")
      }}</span>
      <br />
      <span style="font-size: 11px">{{
        moment(item.dateConfirmed).utcOffset("+1400").format("HH:mm")
      }}</span>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn icon @click="printPaymentSummary(item)" x-small>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
    <template v-slot:no-data> {{ $t("EmptyData.NoBilling") }}</template>
    <template v-slot:footer>
      <v-row>
        <v-col cols="3">
          <label class="footer-title text-uppercase" >{{ $t("Print.TotalFinalAdjustmentPrice") }}:</label>
        </v-col>
        <v-col cols="3">
          <label class="footer-value">{{ convertPriceString(totalFinalPrice) }} VND</label>
        </v-col>
      </v-row>
      
      <div class="fw" v-for="staff in priceByReceptionists" :key="staff.id">
        <label class="footer-title">{{ staff.name }}:</label>
        <label class="footer-value"
          >{{ convertPriceString(staff.price) }} VND
        </label>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import ReportService from "@/services/report";
import { convertPriceString } from "@/plugins/helper";


export default {
  components: {
    
  },
  data() {
    return {
      list: {
        headers: [
          {
            text: this.$t("Common.Date"),
            value: "dateConfirmed",
            align: "center",
          },
          { text: this.$t("Patient.PID"), value: "patientID", align: "center" },
          {
            text: this.$t("Patient.Name"),
            value: "patientName",
            align: "left",
          },
          {
            text: this.$t("Print.Description"),
            value: "orderItemString",
            align: "left",
          },
          {
            text: this.$t("Print.PromotionName"),
            value: "promotionName",
            align: "center",
          },
          {
            text: this.$t("Common.Insurrance"),
            value: "insurrance",
            align: "center",
          },
          {
            text: this.$t("Print.TotalFee"),
            value: "originalPriceStr",
            align: "right",
          },
          {
            text: this.$t("Print.DiscountPrice"),
            value: "promotionPriceStr",
            align: "right",
          },
          {
            text: this.$t("Print.Change"),
            value: "finalAdjustmentPriceStr",
            align: "right",
          },
          {
            text: this.$t("Print.FinalAdjustmentPrice"),
            value: "finalPriceStr",
            align: "left",
          },
          { text: this.$t("Print.Cashier"), value: "staffConfirmName" },
          { text: this.$t("Common.Note"), value: "note", align: "center" },
          { text: "", value: "action" },
        ],
        loading: false,
        items: [],
      },
      totalFinalPrice: 0,
      priceByReceptionists: [],
    };
  },
  methods: {
    moment,
    convertPriceString(value) {
      return convertPriceString(value, false);
    },
    async renderPaymentList(dateRanges, clinicID) {
      if (!dateRanges || !clinicID) return;
      this.list.loading = true;
      var [timeStart, timeEnd] = dateRanges;
      timeStart = moment(timeStart).format("YYYY-MM-DD");
      timeEnd = moment(timeEnd).format("YYYY-MM-DD");
      var result = await ReportService.payment(clinicID, timeStart, timeEnd);
      this.list.loading = false;
      if (!result || result.error) {
        return;
      }
      result.forEach((item) => {
        item.orderItemString = item.orderItems
          .map((o) => o.itemName)
          .join(", ");

        item.finalAdjustmentPrice = item.adjustmentPrice + item.additionalPrice;

        item.originalPriceStr = this.convertPriceString(item.originalPrice);
        item.promotionPriceStr = `(${this.convertPriceString(
          item.promotionPrice
        )})`;
        item.finalAdjustmentPriceStr = this.convertPriceString(
          item.finalAdjustmentPrice
        );
        if (item.finalAdjustmentPriceStr.includes("- ")) {
          item.finalAdjustmentPriceStr = `(${item.finalAdjustmentPriceStr.replace(
            "- ",
            ""
          )})`;
        }
        item.finalPriceStr = this.convertPriceString(item.finalPrice);
        item.insurrance = "N/A";
        for (var key in item) {
          if (!item[key]) {
            item[key] = "N/A";
          }
        }
      });
      this.list.items = result;
      this.calculateTotals();
    },
    calculateTotals() {
      this.totalFinalPrice = this.list.items
        .map((i) => i.finalPrice || 0)
        .reduce((a, b) => a + b, 0);
      console.log(this.list.items);
      this.priceByReceptionists = [
        ...new Set(
          this.list.items.map((i) => i.staffConfirmID).filter((i) => i != null)
        ),
      ].map((id) => {
        return {
          id,
          name: this.list.items.find((i) => i.staffConfirmID == id)
            .staffConfirmName,
          price: this.list.items
            .filter((i) => i.staffConfirmID == id)
            .map((i) => i.finalPrice || 0)
            .reduce((a, b) => a + b, 0),
        };
      });
      console.log(this.priceByReceptionists);
    },
    async printPaymentSummary(payment) {
      // open link with new tab
      var link = `/payment-print?patientID=${payment.patientID}&paymentID=${payment.paymentSummaryID}`;
      window.open(link, "Payment Summary", "width=1100, height=700");
    },
  },
};
</script>

<style lang="scss">
.footer-title {
  float: left;
  display: block;
  text-align: right;
  margin-right: 5px;
  padding: 5px;
}
.footer-value {
  
  float: left;
  display: block;
  text-align: right;
  font-weight: bold;
  padding: 5px;
}
.fw {
  width: 100%;
  float: left;
  display: block;
}
.customHeaderBlock {
  padding: 8px 4px 8px 4px;
  background-color: #eeeeee;
  color: #5f5f5f;
  font-size: 14px;
  font-family:"Be Vietnam", sans-serif;
  line-height: 18px;
}
</style>
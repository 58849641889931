<template>
  <v-card flat class="ma-0 billing-report-component">
    <v-card-text class="pa-5">
      <div class="card-controller">
        <DateRangeSelector v-model="dateRanges" @input="renderPaymentList" />
      </div>
      <div class="card-controller ml-2">
        <ClinicsSelector v-model="clinicID" @input="renderPaymentList" />
      </div>
      <div class="card-controller fl-right">
        <v-btn
          :loading="downloading"
          @click="donwloadExcel"
          class="pa-2 fl-right"
        >
          <v-icon dark class="mr-2" style="margin-top: 3px">
            mdi-download
          </v-icon>
          Download Excel
        </v-btn>
        <v-btn @click="printPDF" class="pa-2 fl-right mr-5">
          <v-icon dark class="mr-2" style="margin-top: 3px">
            mdi-printer
          </v-icon>
          print PDF
        </v-btn>
      </div>
      <div class="mt-0 card-content">
        <!-- <div class="d-block">
          <v-switch
            v-model="viewKendo"
            :label="displayOptionView"
            color="red"
            value="red"
            hide-details
            ></v-switch>
        </div> -->

        <div class="d-block mt-2">
          <PaymentTable ref="PaymentTable" v-if="!viewKendo"/>
          <KendoPayment ref="KendoGridData" v-else/>
        </div>       
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import ReportService from "@/services/report";
import PaymentTable from "./PaymentTable";
import ClinicsSelector from "@/components/ClinicsSelector.vue";
import KendoPayment from "./KendoPayment.vue"
export default {
  components: {
    DateRangeSelector,
    PaymentTable,
    ClinicsSelector,
    KendoPayment
  },
  computed: {
    displayOptionView() {
      return this.viewKendo ? 'Advanced Viewing' : 'Basic View'
    }
  },  
  watch: {
    viewKendo() {
      this.renderPaymentList()
    }
  },
  data() {
    return {
      downloading: false,
      dateRanges: [
        moment().startOf("day").format(),
        moment().endOf("day").format(),
      ],
      clinicID: this.$store.getters.userData.clinicID,
      viewKendo: true,
    };
  },
  mounted() {
    this.renderPaymentList();
  },
  methods: {
    async renderPaymentList() {
      if (this.viewKendo) {
        this.$refs.KendoGridData.renderPaymentList(this.dateRanges, this.clinicID);
      } else {
        this.$refs.PaymentTable.renderPaymentList(this.dateRanges, this.clinicID);
      }
    },
    async donwloadExcel() {
      this.downloading = true;
      var [timeStart, timeEnd] = this.dateRanges;
      timeStart = moment(timeStart).format("YYYY-MM-DD");
      timeEnd = moment(timeEnd).format("YYYY-MM-DD");
      var clinicID = this.clinicID;
      await ReportService.paymentReport(clinicID, timeStart, timeEnd);
      this.downloading = false;
    },
    async printPDF() {
      var [startTime, endTime] = this.dateRanges;
      startTime = moment(startTime).format("YYYY-MM-DD");
      endTime = moment(endTime).format("YYYY-MM-DD");
      var clinicID = this.clinicID;
      var link = `/report-print/payment?clinicID=${clinicID}&startTime=${startTime}&endTime=${endTime}`;
      window.open(link, "Payment Summary Report", "width=1100, height=700");
    },
  },
};
</script>
<style lang ="scss">
.billing-report-component {
  div.card-controller {
    min-width: 260px;
    float: left;
  }
  .fl-right {
    float: right !important;
  }
  div.card-content {
    min-height: 100px;
    width: 100%;
    display: inline-block;
  }
  table {
    border: 1px solid #bdbdbd;
    border-collapse: collapse;
    th {
      border: 1px solid #bdbdbd !important;
      background-color: #eeeeee;
      font-size: 11px;
    }
    td {
      border: 1px solid #bdbdbd !important;
      font-size: 12px !important;
    }
  }
}
</style>